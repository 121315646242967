<!-- Version: 1.2.0 -->
<template>
  <b-container fluid>
    <b-row align-h="around" align-v="center">
      <div>
        <router-link :to="homeRouteName">
          <button class="eh-header-button">
            {{ $t("healthProfessional.menu.home") }}
          </button>
        </router-link>
      </div>
      <div style="text-align: center">
        <span v-for="menu in menuElements" :key="menu.key">
          <router-link
            v-if="
              $loggedUser.SelectedRole == 'Administrator' ||
              (enabledMenuElements &&
                ((menu.uiElementName &&
                  enabledMenuElements.includes(menu.uiElementName)) ||
                  !menu.uiElementName))
            "
            :to="{ name: menu.urlName }"
          >
            <button class="eh-header-view-button">
              {{ $t(menu.langKey) }}
            </button>
          </router-link>
        </span>
      </div>
      <div>
        <router-link to="/">
          <button @click="close" class="eh-header-button">
            {{ $t("base.basic.exit") }}
          </button>
        </router-link>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import { AuthLogic } from "../../../Logic/Backend/auth";

export default {
  name: "MenuLine",
  props: {
    menuElements: Array,
    projectEnabledUiElements: Object,
    enabledMenuElements: Array,
  },
  computed: {
    homeRouteName() {
      switch (this.$loggedUser.SelectedRole) {
        case "Client":
          return { name: "WebAppClientHome" };
        case "HealthProfessional":
          return { name: "WebAppSpecialistHome" };
        case "Partner":
          return { name: "WebAppPartnerHome" };
        case "Administrator":
          return { name: "WebAppAdminHome" };
        default:
          return { name: "Home" };
      }
    },
  },
  methods: {
    close() {
      AuthLogic.clearStorages();
    },
  },
};
</script>
